<template>
    <div class="contact-form__form__core" :style="{opacity: formSending ? 0.5 : 1}">
        <div class="form-group">
            <input id="last_name" type="text" :name="translations.name || 'Imię i nazwisko'" v-model="form.name"
                   v-validate="'required'" :placeholder="translations.name + ' *' || 'Imię i nazwisko *'" class="form-control">
            <span
                class="message message--bottom message--error"
                :style="{
              visibility: errors.has(translations.name || 'Imię i nazwisko') ? 'visible' : 'hidden',
            }"
            >{{ errors.first(translations.name || 'Imię i nazwisko') }}</span
            >
        </div>
        <div class="form-group">
            <input id="company" type="text" name="company" v-model="form.company"
                   :placeholder="translations.company || 'Firma'" class="form-control">
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <input id="job_position" type="text" name="job_position" v-model="form.job_position"
                       :placeholder="translations.job_position || 'Stanowisko'" class="form-control">
            </div>
            <div class="form-group col-md-6">
                <input id="telefon" type="text" name="telefon" v-model="form.phone" @keypress="isNumberOrPlus($event)"
                       :placeholder="translations.telefon || 'Telefon'" class="form-control" v-validate="'min:7|max:15'">
                <span
                    class="message message--bottom message--error"
                    :style="{
              visibility: errors.has('telefon') ? 'visible' : 'hidden',
            }"
                >{{ errors.first('telefon') }}</span
                >
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <input id="email" type="text" name="email" v-model="form.email" v-validate="'required|email'"
                       placeholder="E-mail *" class="form-control">
                <span
                    class="message message--bottom message--error"
                    :style="{
              visibility: errors.has('email') ? 'visible' : 'hidden',
            }"
                >{{ errors.first('email') }}</span
                >
            </div>
            <div class="form-group col-md-6">
                <input id="country" type="text" name="telefon" v-model="form.country"
                       :placeholder="translations.country || 'Kraj'" class="form-control">
            </div>
        </div>
        <div class="form-group">
            <div class="form-check">
                <input type="checkbox" name="translations.term || 'regulamin'" v-model="form.terms"
                       v-validate="'required'" class="form-check-input" id="gridCheck1">
                <label class="form-check-label" for="gridCheck1">
                <span>
                    {{
                        translations.term_text || 'Podane dane osobowe będą przetwarzane, aby skontaktować się z Panem/Panią i udzielić odpowiedzi na przesłane pytanie. *'
                    }}
                </span>
                    <span class="expandable-text">
                    {{
                            translations.term_text_expanded || 'Administratorem danych osobowych jest ICsec S.A. z siedzibą w Poznaniu (60-449),przy ul. Wichrowej 1A. Więcej informacji o przetwarzaniu danych osobowych, w tym o przysługujących Państwu uprawnieniach, znajduje się w naszej'
                        }}
                    <a :href="translations.term_url || '/polityka-prywatnosci'" target="_blank">{{ translations.term_title || 'Polityce Prywatności'}}</a>.
                </span>
                    <em v-on:click="readMorePolicies">{{ translations.term_more || 'Czytaj dalej'}}</em>
                </label>
                <div class="d-block checkbox-error-msg-container">
                <span class="message message--bottom message--error"
                      v-show="errors.has(translations.term || 'regulamin')">{{
                        errors.first(translations.term || 'regulamin')
                    }}</span>
                </div>
            </div>
        </div>


        <div class="message message--top message--error" v-if="serverError">{{ serverError }}</div>
        <div class="message message--top message--success" v-if="serverSuccess">{{ serverSuccess }}</div>

        <button :disabled="!isValid || formSending" :class="isValid && !formSending ? `valid` : `invalid`"
                class="btn btn-primary"
                @click="sendForm()">{{ translations.contact_us || "Wyślij" }}
        </button>

    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "ContactForm",
    data() {
        return {
            form: {
                name: "",
                company: "",
                job_position: "",
                phone: "",
                email: "",
                country: "",
                terms: false,
            },
            serverError: "",
            serverSuccess: "",
            formSending: false,
        }
    },
    methods: {
        sendForm() {
            this.$validator
                .validateAll()
                .then((isValid) => {
                    if (isValid) {
                        this.formSending = true;
                        this.clearServerErrors();
                        this.clearServerSuccess();

                        const formData = new FormData();
                        formData.append("action", "send-contact-form");
                        formData.append("security", window.icsecContactFormNonce);
                        Object.keys(this.form).map(key => {
                            formData.append(key, this.form[key]);
                        })

                        axios.post(themosis.ajaxurl, formData)
                            .then((r) => {
                                this.clearFields();
                                if (r.data && r.data && r.data.message) {
                                    this.serverSuccess = r.data.message;
                                } else {
                                    this.serverSuccess = "Success"
                                }
                                this.$nextTick().then(() => {
                                    this.$validator.reset();
                                    this.errors.clear();
                                });
                            })
                            .catch((e) => {
                                if (e.response.data && e.response.data && e.response.data.error) {
                                    this.serverError = e.response.data.error;
                                } else {
                                    this.serverError = "Error"
                                }
                            })
                            .finally(() => {
                                this.formSending = false;
                            });
                    }
                })
                .catch((data) => {
                    console.log(data);
                });

        },

        clearServerErrors() {
            this.serverError = "";
        },

        clearServerSuccess() {
            this.serverSuccess = "";
        },

        clearFields() {
            Object.keys(this.form).map(key => {
                if (key === "terms") {
                    this.form.terms = false;
                    return;
                }
                this.form[key] = "";
            })
        },
        readMorePolicies(e) {
            e.preventDefault();
            let button  = e.target;
            button.style.display = 'none';
            button.previousElementSibling.style.display = 'block';
        },

        isNumberOrPlus: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;

            if (charCode === 43) {
                return true;
            }

            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
    },
    computed: {
        isValid() {
            return Object.keys(this.fields).every(field => {
                return this.fields[field] && this.fields[field].valid;
            });
        },

        translations() {
            return icsecDemoForm;
        }

    }
}
</script>

<style lang="scss" scoped>
    .send-button {
        transition: .3s all;

        &.invalid {
            pointer-events: none;
            opacity: .3;
        }
    }

    .message {
        display: flex;
        align-items: center;
        height: 24px;
        font-size: 10px;

        &--bottom {
            margin-bottom: -20px;
            margin-top: -2px;
        }

        &--top {
            margin-bottom: 7px;
        }

        &--error {
            color: red;
        }

        &--success {
            color: #68bb4b;
        }
    }
</style>

import Vue from "vue";
import ContactForm from "./components/forms/ContactForm";
import VeeValidate from 'vee-validate';

const SELECTOR = "#contact-form";

document.addEventListener("DOMContentLoaded", () => {
    const contactForm = document.querySelector(SELECTOR);

    if (contactForm) {
        const VueValidationPL = require('vee-validate/dist/locale/pl');
        const VueValidationEN = require('vee-validate/dist/locale/en');
        const locale = icsecContactForm && icsecContactForm.locale ? icsecContactForm.locale : 'pl';

        Vue.use(VeeValidate,{
            locale,
            silentTranslationWarn: true,
            dictionary: {
                pl: VueValidationPL,
                en: VueValidationEN,
            }
        });

        new Vue({
            el: SELECTOR,
            render: h => h(ContactForm),
        });
    }
});
